<template>
  <v-card id="account-setting-card">
    <v-form ref="form" lazy-validation>
      <v-stepper v-model="tab">
        <v-stepper-header>
          <v-stepper-step editable step="1"> Empleado </v-stepper-step>
          <v-stepper-step editable step="2"> Permisos </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-text>
              <v-row>
                <v-col lg="6" md="6" cols="12">
                  <v-text-field
                    :rules="reglas.ci"
                    v-model="datos.empleado.ci"
                    label="Ci"
                    required
                    outlined
                    dense
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>
                  <v-text-field
                    :rules="reglas.nombre"
                    v-model="datos.empleado.nombre"
                    label="Nombre"
                    required
                    outlined
                    dense
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>

                  <v-text-field
                    :rules="reglas.apellido"
                    v-model="datos.empleado.apellido"
                    label="Apellido"
                    required
                    outlined
                    dense
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>
                  <v-textarea
                    v-model="datos.empleado.direccion"
                    label="Direccion"
                    required
                    outlined
                    name="input-7-4"
                  ></v-textarea>
                </v-col>
                <v-col lg="6" md="6" cols="12">
                  <div>
                    <v-avatar rounded size="120" class="me-6">
                      <v-img :src="urlImg"></v-img>
                    </v-avatar>
                    <v-btn x-small color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">
                      <v-icon class="d-sm-none">
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                      <span class="d-none d-sm-block">Subir imagen</span>
                    </v-btn>

                    <input
                      ref="refInputEl"
                      @change="ChangeImg"
                      type="file"
                      accept=".jpeg,.png,.jpg,GIF"
                      :hidden="true"
                    />

                    <v-btn x-small color="error" outlined class="mt-5" @click="resetImg()"> Reset </v-btn>
                    <p class="text-sm mt-5">Permitido JPG, GIF or PNG. Tamaño maximo 800K</p>
                  </div>
                  <v-text-field
                    v-model="datos.empleado.telefono"
                    label="Telefono"
                    required
                    outlined
                    dense
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>
                  <v-select
                    v-model="datos.empleado.tipo_empleado"
                    :items="empleadosTipo"
                    item-text="descripcion"
                    item-value="id"
                    :rules="reglas.tipo_empleado"
                    label="Tipo Empleado"
                    required
                    hide-details="auto"
                    class="mb-6"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-divider class="mb-4"></v-divider>
              <v-row>
                <v-col cols="12" sm="8" md="6">
                  <v-text-field
                    :rules="reglas.usuario.usuario"
                    label="Usuario"
                    v-model="datos.empleado.usuario.usuario"
                    required
                    outlined
                    dense
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>

                  <!-- new password -->
                  <v-text-field
                    :rules="reglas.usuario.clave"
                    v-model="datos.empleado.usuario.clave"
                    :type="isNewPasswordVisible ? 'text' : 'password'"
                    :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                    label="Contraseña"
                    outlined
                    dense
                    persistent-hint
                    placeholder="*********"
                    @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                  ></v-text-field>

                  <!-- confirm password -->
                  <v-text-field
                    :rules="reglas.usuario.clave2"
                    v-model="datos.empleado.usuario.clave2"
                    :type="isCPasswordVisible ? 'text' : 'password'"
                    :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                    label="Confirmar contraseña"
                    outlined
                    dense
                    class="mt-3"
                    placeholder="*********"
                    @click:append="isCPasswordVisible = !isCPasswordVisible"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card-text>
              <v-tabs v-model="tab1" background-color="deep-purple accent-4" centered icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1"> permisos por rol </v-tab>
                <v-tab href="#tab-2">permisos por usuario </v-tab>
                <v-tab href="#tab-3">permisos reportes </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab1">
                <v-tab-item value="tab-1">
                  <v-card flat>
                    <v-card-text>
                      <div class="pa-3">
                        <v-card-title class="flex-nowrap">
                          <v-icon class="text--primary me-3">
                            {{ icons.mdiKeyOutline }}
                          </v-icon>
                          <span class="text-break">Roles Generales </span>
                        </v-card-title>

                        <v-card-text>
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <v-treeview
                                  v-model="selectionRolGeneral"
                                  :items="FiltrarRoles(false)"
                                  selection-type="leaf"
                                  item-children="children"
                                  selectable
                                  return-object
                                  item-text="descripcion"
                                  item-key="id"
                                ></v-treeview>
                              </v-col>
                              <v-divider vertical></v-divider>
                              <v-col class="pa-6" cols="6">
                                <template v-if="!selectionRolGeneral.length">
                                  <span class="error--text">* No tiene permisos seleccionados.</span>
                                </template>
                                <template v-else>
                                  <div style="max-height: 200px; overflow-y: auto">
                                    <div v-for="node in selectionRolGeneral" :key="node.id + 'a'">
                                      {{ node.descripcion }}
                                    </div>
                                  </div>
                                </template>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card-text>

                        <!-- action buttons -->
                      </div>
                      <v-divider></v-divider>
                      <div class="pa-3">
                        <v-card-title class="flex-nowrap">
                          <v-icon class="text--primary me-3">
                            {{ icons.mdiKeyOutline }}
                          </v-icon>
                          <span class="text-break">Roles por Puesto </span>
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-treeview
                                v-model="selectionRolPuestos"
                                :items="RolesPuestos"
                                selection-type="leaf"
                                item-children="children"
                                selectable
                                return-object
                                item-text="nombre"
                                item-key="onlyid"
                              ></v-treeview>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="pa-6" cols="6">
                              <template v-if="!selectionRolPuestos.length">
                                <span class="error--text">* No tiene permisos seleccionados.</span>
                              </template>
                              <template v-else>
                                <div style="max-height: 200px; overflow-y: auto">
                                  <div v-for="node in selectionRolPuestos" :key="node.onlyid + 'a'">
                                    {{ node.nombre + ' ' + node.nombreRol }}
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <!-- action buttons -->
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-card flat>
                    <v-card-text>
                      <div class="pa-3">
                        <v-card-title class="flex-nowrap">
                          <v-icon class="text--primary me-3">
                            {{ icons.mdiKeyOutline }}
                          </v-icon>
                          <span class="text-break">Permisos Generales </span>
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-treeview
                                v-model="selectionPermisosGeneral"
                                :items="FiltrarArboles(-1)"
                                selection-type="leaf"
                                item-children="childrenacciones"
                                selectable
                                return-object
                                item-text="nombre"
                                item-key="onlyid"
                              ></v-treeview>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="pa-6" cols="6">
                              <template v-if="!selectionPermisosGeneral.length">
                                <span class="error--text">* No tiene permisos seleccionados.</span>
                              </template>
                              <template v-else>
                                <div style="max-height: 200px; overflow-y: auto">
                                  <div v-for="node in selectionPermisosGeneral" :key="node.onlyid + 'a'">
                                    {{ node.nombre + ' ' + node.nombre_modulo }}
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <!-- action buttons -->
                      </div>
                      <v-divider></v-divider>
                      <div class="pa-3">
                        <v-card-title class="flex-nowrap">
                          <v-icon class="text--primary me-3">
                            {{ icons.mdiKeyOutline }}
                          </v-icon>
                          <span class="text-break">Permisos por puesto </span>
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-treeview
                                v-model="selectionPermisosPuestos"
                                :items="FiltrarArboles(0)"
                                selection-type="leaf"
                                item-children="childrenacciones"
                                selectable
                                return-object
                                item-text="nombre"
                                item-key="onlyid"
                              ></v-treeview>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="pa-6" cols="6">
                              <template v-if="!selectionPermisosPuestos.length">
                                <span class="error--text">* No tiene permisos seleccionados.</span>
                              </template>
                              <template v-else>
                                <div style="max-height: 200px; overflow-y: auto">
                                  <div v-for="node in selectionPermisosPuestos" :key="node.onlyid + 'b'">
                                    {{ node.nombre_puesto + ': ' + node.nombre + ' ' + node.nombre_modulo }}
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <!-- action buttons -->
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <v-card flat>
                    <v-card-text>
                      <div class="pa-3">
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-treeview
                                v-model="selectionPermisosReportes"
                                :items="reportes"
                                selection-type="leaf"
                                item-children="children"
                                selectable
                                return-object
                                item-text="nombre"
                                item-key="id"
                              ></v-treeview>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="pa-6" cols="6">
                              <template v-if="!selectionPermisosReportes.length">
                                <span class="error--text">* No tiene permisos seleccionados.</span>
                              </template>
                              <template v-else>
                                <div style="max-height: 200px; overflow-y: auto">
                                  <div v-for="node in selectionPermisosReportes" :key="node.id">
                                    {{
                                      node.nombre_reporte == node.nombre
                                        ? node.nombre_reporte
                                        : `${node.nombre_reporte} ${node.nombre}`
                                    }}
                                  </div>
                                </div>
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <!-- action buttons -->
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-form>
    <v-card-text class="text-right mt-6">
      <v-btn class="secondary" small>limpiar</v-btn>
      <v-btn class="primary" @click="GuardarDatos()" small>Guardar</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import SeguridadService from '@/api/servicios/SeguridadService'
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref, context, onBeforeMount } from '@vue/composition-api'
import PuestoService from '@/api/servicios/PuestoService'
// demos
import goTo from 'vuetify/lib/services/goto'
import store from '@/store'

export default {
  components: {},
  setup(props, context) {
    const tab = ref('1')
    const tab1 = ref('#tab-1')

    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const selectionRolGeneral = ref([])
    const selectionRolPuestos = ref([])
    const selectionPermisosGeneral = ref([])
    const selectionPermisosPuestos = ref([])
    const selectionPermisosReportes = ref([])
    // tabs
    const tabs = [
      { title: 'Empleado', icon: mdiAccountOutline },
      { title: 'Seguridad', icon: mdiLockOpenOutline },
    ]

    const EnviarDatos = item => {
      console.log('EnviarDatos', item)
      datos.value = item

      selectionPermisosReportes.value = datos.value.empleado.usuario.permisos_usuario_reportes
      selectionPermisosGeneral.value = datos.value.empleado.usuario.permisos_usuario_general
      selectionPermisosPuestos.value = datos.value.empleado.usuario.permisos_usuario_puesto
      selectionRolGeneral.value = datos.value.empleado.usuario.permisos_rol_general
      selectionRolPuestos.value = datos.value.empleado.usuario.permisos_rol_puestos

      console.log(item)
    }

    const urlImg = ref(require('@/assets/images/avatars/1.png'))
    const empleadosTipo = ref([])
    const RolesPuestos = ref([])
    onBeforeMount(() => {
      SeguridadService.obtenerEmpleadoTipos()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            empleadosTipo.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })
    })
    const resetImg = () => {
      datos.value.imgInput = null
      urlImgSet()
    }
    const ChangeImg = e => {
      datos.value.imgInput = e.target.files[0]
      console.log(URL.createObjectURL(datos.value.imgInput))
      urlImgSet()
    }
    const urlImgSet = () => {
      if (!datos.value.imgInput) {
        if (datos.value.img != '') {
          console.log(datos.value.img)
          console.log('imgane null')
          urlImg.value = `http://localhost:5001/empleado/img/${datos.value.img}`
        } else {
          console.log('imgane null')
          urlImg.value = require('@/assets/images/avatars/1.png')
        }
      } else {
        const imgs = URL.createObjectURL(datos.value.imgInput)
        console.log('imgane')
        urlImg.value = imgs
      }
    }
    const reglas = {
      apellido: [
        v => !!v || 'Apellido es requerido',
        v => (v && v.length <= 20 && v.length >= 3) || 'Apellido debe ser valido',
      ],
      ci: [
        v => !!v || 'Cedula es requerido',
        v => (v && v.length >= 7 && v.length <= 8) || 'Cedula Invalida',
        v => !Number.isInteger(v) || 'Cedula Invalida',
      ],
      clave: '',
      clave2: '',
      direccion: '',
      id: -1,
      id_usuario: -1,
      img: '',
      modulo: '',
      nombre: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length <= 20 && v.length >= 3) || 'Nombre debe ser valido',
      ],
      telefono: '',
      tipo_empleado: [v => !!v || 'Tipo empleado es requerido'],

      usuario: {
        usuario: [
          v => !!v || 'Usuario es requerido',
          v => (v && v.length <= 20 && v.length >= 3) || 'Usuario debe ser valido',
        ],
        clave: [
          v => !!v || 'contraseñas es requerido',
          v => (v && v.length >= 4) || 'Contraseñas debe contener almenos 4 caracteres',
        ],
        clave2: [
          v => !!v || 'Confirmacion de contraseñas es requerido',
          v => (v && v == datos.value.empleado.usuario.clave) || 'Las contraseñas deben coincidir',
        ],
      },
    }
    const form = ref(null)

    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }

    const GuardarDatos = async () => {
      if (validarForm()) {
        console.log(datos.value)

        let imagen = ''
        if (datos.value.imgInput) {
          const form2 = new FormData()
          form2.append('file', datos.value.imgInput, 'img.jpg')
          //SUBIR IMAGEN
          console.log('asq')

          await SeguridadService.subirImgEmpleado(form2)
            .then(response => {
              console.log(response)
              imagen = response.data.img
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              console.log('Termino')
            })
        }
        //CREAR O MODIFICAR
        await SeguridadService.modificarEmpleado({
          ...datos.value.empleado,
          usuario: {
            ...datos.value.empleado.usuario,
            permisos_usuario_reportes: selectionPermisosReportes.value,
            permisos_usuario_general: selectionPermisosGeneral.value,
            permisos_usuario_puesto: selectionPermisosPuestos.value,
            permisos_rol_general: selectionRolGeneral.value,
            permisos_rol_puestos: selectionRolPuestos.value,
          },
          img: imagen == '' ? datos.value.img : imagen,
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'GUARDADO CON EXITO') {
              ResetFormulario()
              context.emit('CargarListaEmpleados')
              store.commit('setAlert', {
                message: 'Guardado con exito',
                type: 'success',
              })
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            console.log(error)
            store.commit('setAlert', {
              message: 'Disculpe se produjo un error al momento de guardar el empleado : ' + error,
              type: 'error',

              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'GuardarDatos-empleadoConfiguracion',
            })
          })
          .finally(() => {
            console.log('Termino')
          })
      } else {
        goTo(0)
        store.commit('setAlert', {
          message: 'Validar los datos del empleado',
          type: 'warning',
        })
      }

      console.log(datos)
    }

    const ResetFormulario = () => {
      datos.value = JSON.parse(JSON.stringify(configuracionEmpleadoDataInit))
      selectionPermisosReportes.value = []
      selectionPermisosGeneral.value = []
      selectionPermisosPuestos.value = []
      selectionRolGeneral.value = []
      selectionRolPuestos.value = []
    }
    const configuracionEmpleadoDataInit = {
      empleado: {
        apellido: '',
        ci: '',
        direccion: '',
        id: -1,
        id_usuario: -1,
        img: '',
        modulo: '',
        nombre: '',
        telefono: '',
        tipo_empleado: '',
        usuario: {
          id: 0,
          usuario: '',
          clave: '',
          clave2: '',
          permisos_rol_general: [],
          permisos_rol_puestos: [],
          permisos_usuario_general: [],
          permisos_usuario_puesto: [],
        },
      },
      permisos: {
        bio: 'The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.',
        birthday: 'February 22, 1995',
        phone: '954-006-0844',
        website: 'https://themeselection.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }
    // account settings data
    const datos = ref(JSON.parse(JSON.stringify(configuracionEmpleadoDataInit)))
    const reportes = ref([])
    const roles = ref([])
    const arbol = ref([])
    const puestos = ref([])

    onBeforeMount(async () => {
      await SeguridadService.reportesItemTree({ id_usuario: store.state.user.id })
        .then(response => {
          console.log(response)

          reportes.value = response.data
        })
        .catch(error => {
          console.log(error)
        })

      await SeguridadService.obtenerRol({ id_usuario: store.state.user.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            roles.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })
      await SeguridadService.arbolModulosListar({ id_usuario: store.state.user.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            arbol.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })

      await PuestoService.obtenerPuestos({ ind_principal: true })
        .then(response => {
          console.log('obtenerPuestos', response.data.datos)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            puestos.value = response.data.datos
            FiltrarRolesSucursales()
          }
        })
        .catch(error => {
          console.log(error)
        })
    })

    const FiltrarRoles = dato => {
      const valor = roles.value.filter(item => item.ind_sucursal == dato)
      if (valor.length > 0) {
        return valor
      }
      return []
    }

    const FiltrarArboles = tipo_puesto => {
      const valor = arbol.value.filter(
        item => item.id_puesto == tipo_puesto || (tipo_puesto == 0 && item.id_puesto != -1),
      )
      if (valor.length > 0) {
        if (tipo_puesto == -1) {
          return valor[0].arbol
        } else {
          let result = []
          valor.forEach(i => {
            result.push({ ...i, childrenacciones: i.arbol })
          })
          console.log(result)
          return result
        }
      }
      return []
    }

    const FiltrarRolesSucursales = () => {
      let valor = []
      let onlyid = 100000

      FiltrarRoles(true).forEach(element => {
        let puesto = []

        puestos.value.forEach(i => {
          onlyid += 1
          puesto.push({
            ...i,
            id_puesto: i.id,
            onlyid: 'puesto:' + i.id + '-rol:' + element.id,
            id_rol: element.id,
            nombreRol: element.descripcion,
          })
        })
        onlyid += 1
        valor.push({ ...element, onlyid: 'rol:' + element.id, children: [...puesto], nombre: element.descripcion })
      })

      RolesPuestos.value = valor

      console.log('RolesPuestos')
      console.log(RolesPuestos)
    }

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      EnviarDatos,
      GuardarDatos,
      ResetFormulario,
      datos,
      reglas,
      form,

      urlImg,
      resetImg,
      ChangeImg,
      empleadosTipo,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      isCPasswordVisible,
      tab1,
      selectionRolGeneral,
      selectionRolPuestos,
      selectionPermisosGeneral,
      selectionPermisosReportes,
      selectionPermisosPuestos,
      FiltrarRoles,
      FiltrarArboles,
      reportes,
      roles,
      arbol,
      puestos,
      RolesPuestos,
      FiltrarRolesSucursales,
    }
  },
}
</script>
