<template>
  <div>
    <p class="text-2xl mb-6">Empleados</p>
   
      <v-row>
        <v-col lg="12">
          <empleado-configuracion @CargarListaEmpleados="CargarListaEmpleados" ref="EmpleadoConfiguracionRef">
          </empleado-configuracion>
        </v-col>
        <v-col lg="12">
          <empleado-lista ref="EmpleadoListaRef" @GetEditarEmpleado="GetEditarEmpleado"></empleado-lista>
        </v-col>
      </v-row>
    
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import EmpleadoConfiguracion from './empleado-configuracion/EmpleadoConfiguracion.vue'
import EmpleadoLista from './empleado-configuracion/EmpleadoLista.vue'
import { ref, onBeforeMount, watch } from '@vue/composition-api'
// demos

export default {
  components: { EmpleadoConfiguracion, EmpleadoLista },
  setup() {
    const EmpleadoConfiguracionRef = ref({})
    const EmpleadoListaRef = ref(null)

    const CargarListaEmpleados = () => {
    
      EmpleadoListaRef.value.cargarEmpleado()
    }

    const GetEditarEmpleado = data => {
      console.log('GetEditarEmpleado 2')
      EmpleadoConfiguracionRef.value.EnviarDatos({
        empleado: {
          apellido: data.apellido,
          ci: data.ci.toString(),
          direccion: data.direccion,
          id: data.id,
          img: data.img,
          nombre: data.nombre,
          telefono: data.telefono,
          tipo_empleado: data.id_empleado_tipo,
          usuario: { ...data.usuario, clave2: data.usuario.clave },
        },
        permisos: {
          bio: 'The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.',
          birthday: 'February 22, 1995',
          phone: '954-006-0844',
          website: 'https://themeselection.com/',
          country: 'USA',
          languages: ['English', 'Spanish'],
          gender: 'male',
        },
      })
    }

    return {
      EmpleadoConfiguracionRef,
      GetEditarEmpleado,
      EmpleadoListaRef,
      CargarListaEmpleados
    }
  },
}
</script>
