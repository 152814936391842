
<template>
  <div>
    <v-card>
      <v-card-title
        >Lista Empleados

        <v-btn small icon class="ml-2" @click="cargarEmpleado()">
          <v-icon> {{ icons.mdiRefresh }} </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" offset-md="8" md="4">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="usuarioList"
        :search="search"
        :items-per-page="5"
        :loading="usuarioListLoad"
        class="table-kitchen-sink"
      >
        <!-- product -->

        <template #[`item.id`]="{ item }">
          <div class="align-center">
            {{ item.id }}
            <div>
              <v-avatar size="1.875rem" color="primary" class="v-avatar-light-bg primary--text">
                <v-img v-if="item.img" :src="`${config.api_imagenes}/empleado/img/${item.img}`"></v-img> 
                <v-img v-else :src="require('@/assets/images/avatars/1.png')"></v-img>
              </v-avatar>
            </div>
          </div>
        </template>

        <template #[`item.empleado_tipo_descripcion`]="{ item }">
          <span class="text-no-wrap">{{ item.empleado_tipo_descripcion }}</span>
        </template>
        <template #[`item.estado`]="{ item }">
          <v-chip x-small v-if="item.estado == 5" class="ma-2" color="success">Activo</v-chip>
          <v-chip x-small v-else class="ma-2" color="error">Bloqueado</v-chip>
        </template>
        <template #[`item.accion`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditarEmpleado(item)">
                <v-icon>{{ icons.mdiAccountEdit }}</v-icon>
              </a>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on" @click="modificarEmpleadoEstado(item)">
                <v-icon :color="item.estado == 5 ? 'error' : 'success'">{{
                  item.estado == 5 ? icons.mdiAccountCancel : icons.mdiAccountCheck
                }}</v-icon>
              </a>
            </template>
            <span>{{ item.estado == 5 ? 'Bloquear' : 'Activar' }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { onBeforeMount, ref, context } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiMouse,
  mdiGlasses,
  mdiWatchVariant,
  mdiBagPersonalOutline,
  mdiTapeDrive,
  mdiBluetooth,
  mdiControllerClassicOutline,
  mdiHomeOutline,
  mdiVirtualReality,
  mdiShoeSneaker,
  mdiFlash,
  mdiProjector,
  mdiIpod,
  mdiKeyboardVariant,
  mdiCellphone,
  mdiTelevision,
  mdiGoogleHome,
  mdiApple,
  mdiHeadphones,
  mdiDesktopMac,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiRefresh,
} from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
import goTo from 'vuetify/lib/services/goto'
import config from '@/api/config'

export default {
  setup(props, context) {
    const search = ref('')
    const usuarioList = ref([])
    const usuarioListLoad = ref(false)
    const categoryIcons = [
      { name: 'Mouse', icon: mdiMouse, color: 'warning' },
      { name: 'Glass', icon: mdiGlasses, color: 'primary' },
      { name: 'Smart Watch', icon: mdiWatchVariant, color: 'success' },
      { name: 'Bag', icon: mdiBagPersonalOutline, color: 'info' },
      { name: 'Storage Device', icon: mdiTapeDrive, color: 'warning' },
      { name: 'Bluetooth', icon: mdiBluetooth, color: 'error' },
      { name: 'Gaming', icon: mdiControllerClassicOutline, color: 'warning' },
      { name: 'Home', icon: mdiHomeOutline, color: 'error' },
      { name: 'VR', icon: mdiVirtualReality, color: 'primary' },
      { name: 'Shoes', icon: mdiShoeSneaker, color: 'success' },
      { name: 'Electronics', icon: mdiFlash, color: 'info' },
      { name: 'Projector', icon: mdiProjector, color: 'warning' },
      { name: 'IPod', icon: mdiIpod, color: 'error' },
      { name: 'Keyboard', icon: mdiKeyboardVariant, color: 'primary' },
      { name: 'Smart Phone', icon: mdiCellphone, color: 'success' },
      { name: 'Smart TV', icon: mdiTelevision, color: 'info' },
      { name: 'Google Home', icon: mdiGoogleHome, color: 'warning' },
      { name: 'Mac', icon: mdiApple, color: 'error' },
      { name: 'Headphone', icon: mdiHeadphones, color: 'primary' },
      { name: 'IMac', icon: mdiDesktopMac, color: 'success' },
      { name: 'IPhone', icon: mdiApple, color: 'warning' },
    ]
    const GetEditarEmpleado = item => {
      console.log(item)
      context.emit('GetEditarEmpleado', { ...item })
      goTo(0)
    }
    onBeforeMount(() => {
      cargarEmpleado()
    })

    const modificarEmpleadoEstado = item => {
      SeguridadService.modificarEmpleadoEstado({ ...item, estado: item.estado == 5 ? 6 : 5 })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'GUARDADO CON EXITO') {
            usuarioList.value.find(it => it.id == item.id).estado = item.estado == 5 ? 6 : 5
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const cargarEmpleado = () => {
      usuarioListLoad.value = true
      SeguridadService.obtenerEmpleados()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            usuarioList.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          usuarioListLoad.value = false
        })
    }

    const statusColor = { Confirmed: 'primary', Completed: 'success', Cancelled: 'error' }

    const categoryIconFilter = categoryName => {
      const index = categoryIcons.findIndex(category => category.name === categoryName)

      if (index !== -1) return [{ icon: categoryIcons[index].icon, color: categoryIcons[index].color }]

      return false
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiRefresh,
      },
      search,
      statusColor,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'NOMBRE', value: 'nombre' },
        { text: 'APELLIDO', value: 'apellido' },
        { text: 'CI', value: 'ci' },
        { text: 'TIPO', value: 'empleado_tipo_descripcion' },
        { text: 'TELEFONO', value: 'category' },
        { text: 'USUARIO', value: 'usuario.usuario' },
        { text: 'ESTADO', value: 'estado' },
        { text: 'ACCION', value: 'accion', sortable: false },
      ],
      categoryIconFilter,
      usuarioList,
      categoryIcons,
      usuarioListLoad,
      GetEditarEmpleado,
      cargarEmpleado,
      modificarEmpleadoEstado,
      config
    }
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
