var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Lista Empleados "),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.cargarEmpleado()}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.usuarioList,"search":_vm.search,"items-per-page":5,"loading":_vm.usuarioListLoad},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-center"},[_vm._v(" "+_vm._s(item.id)+" "),_c('div',[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"1.875rem","color":"primary"}},[(item.img)?_c('v-img',{attrs:{"src":((_vm.config.api_imagenes) + "/empleado/img/" + (item.img))}}):_c('v-img',{attrs:{"src":require('@/assets/images/avatars/1.png')}})],1)],1)])]}},{key:"item.empleado_tipo_descripcion",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.empleado_tipo_descripcion))])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [(item.estado == 5)?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":"","color":"success"}},[_vm._v("Activo")]):_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":"","color":"error"}},[_vm._v("Bloqueado")])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2",on:{"click":function($event){return _vm.GetEditarEmpleado(item)}}},'a',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccountEdit))])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2",on:{"click":function($event){return _vm.modificarEmpleadoEstado(item)}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"color":item.estado == 5 ? 'error' : 'success'}},[_vm._v(_vm._s(item.estado == 5 ? _vm.icons.mdiAccountCancel : _vm.icons.mdiAccountCheck))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.estado == 5 ? 'Bloquear' : 'Activar'))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }